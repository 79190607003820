import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import 'react-activity/dist/react-activity.css';
import {
  TextInputAdminOrcamentos,
  SelectInputAdmin,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';

import {
  DivPermissoesAcesso,
  DivLabelPermissoesAcesso,
  DivRotasPermissoes,
} from './styles';

import DivSublinhada from '~/componentes/div_sublinhada';
import Button from '@material-ui/core/Button';
import TitleForm from '~/componentes/titleForm';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LixeiraOutlineIcon from '@material-ui/icons/DeleteOutline';
import {Accordion, AccordionItem} from '~/componentes/accordion';

import FuncaoComponente from '../funcao_componente';

export const DivPrincipal = styled.div``;

const RelatorioComponente = (props = {}) => {
  const {
    DisplayMode,
    salvarRelatorio,

    nome,
    _id,
    descricao,
    qtd_horas,
    prazo,
    ordem,
    TiposFuncoes,
    funcoes_backend,
    index,
  } = props;

  const [Inicio, setInicio] = useState(true);
  const [ID, setID] = useState(_id);

  const [Nome, setNome] = useState(nome);

  const [Descricao, setDescricao] = useState(descricao);
  const [QTDHoras, setQTDHoras] = useState(qtd_horas ?? 0);
  const [Prazo, setPrazo] = useState(prazo ?? 0);
  const [Ordem, setOrdem] = useState(ordem ?? 0);

  const [FuncoesSelecionadas, setFuncoesSelecionadas] =
    useState(funcoes_backend);

  useEffect(() => {
    //console.log('RelatorioAux : ' + JSON.stringify(props));

    if (funcoes_backend && funcoes_backend.length > 0) {
      setFuncoesSelecionadas(funcoes_backend);
    } else {
      setFuncoesSelecionadas([{}]);
    }
  }, [Inicio]);

  const salvarRelatorioAux = async () => {
    let RelatorioAux = getDados();
    let Retorno = await salvarRelatorio(RelatorioAux, index);
  };

  const getDados = () => {
    return {
      _id: ID,
      nome: Nome,
      descricao: Descricao,
      qtd_horas: QTDHoras,
      prazo: Prazo,
      ordem: Ordem,
      funcoes_backend: FuncoesSelecionadas,
    };
  };

  const salvarFuncao = async (FuncaoItem, index_funcao) => {
    //console.log('FuncaoItem : ' + JSON.stringify(FuncaoItem));
    let DadosRelatorio = getDados();
    let FuncoesAux = FuncoesSelecionadas;

    FuncoesAux[index_funcao] = FuncaoItem;

    setFuncoesSelecionadas(FuncoesAux);

    DadosRelatorio.funcoes_backend = FuncoesAux;
    salvarRelatorio(DadosRelatorio, index);
  };

  const excluirFuncao = async (FuncaoItem, index_funcao) => {
    //console.log('FuncaoItem : ' + JSON.stringify(FuncaoItem));

    let DadosRelatorio = getDados();

    let FuncoesAux = FuncoesSelecionadas;
    FuncoesAux.splice(index_funcao, 1);

    setFuncoesSelecionadas(FuncoesAux);
    DadosRelatorio.funcoes = FuncoesAux;
    salvarRelatorio(DadosRelatorio, index);
  };

  const adicionarFuncao = async () => {
    setFuncoesSelecionadas([...FuncoesSelecionadas, {}]);
  };

  return (
    <DivPrincipal>
      <DivPermissoesAcesso>
        <TextInputAdminOrcamentos
          label="Nome"
          autoComplete="nome"
          value={Nome}
          onChange={({target}) => setNome(target.value)}
          disabled={!!DisplayMode}
        />

        <TextInputAdminOrcamentos
          label="Descrição"
          autoComplete="nome"
          value={Descricao}
          onChange={({target}) => setDescricao(target.value)}
          disabled={!!DisplayMode}
          multiline
          minRows={3}
          maxRows={30}
        />

        <TextInputAdminOrcamentos
          label="QTD Horas"
          autoComplete="nome"
          value={QTDHoras}
          onChange={({target}) => setQTDHoras(target.value)}
          disabled={true}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Prazo"
          autoComplete="nome"
          value={Prazo}
          onChange={({target}) => setPrazo(target.value)}
          disabled={true}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Ordem"
          autoComplete="nome"
          value={Ordem}
          onChange={({target}) => setOrdem(target.value)}
          disabled={!!DisplayMode}
        />

        <DivSublinhada>
          <TitleForm>Funções Backend</TitleForm>
          <IconButton onClick={adicionarFuncao}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {FuncoesSelecionadas &&
              FuncoesSelecionadas.map((Item, index) => {
                return (
                  <AccordionItem
                    header={`${Item.funcao_nome} - ${Item.qtd_horas} horas`}>
                    <IconButton onClick={() => excluirFuncao(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <FuncaoComponente
                      {...Item}
                      salvarFuncao={salvarFuncao}
                      TiposFuncoes={TiposFuncoes}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivBotao style={{float: 'none'}}>
          <Button
            variant="contained"
            color="secundary"
            onClick={salvarRelatorioAux}>
            Salvar Relatório
          </Button>
        </DivBotao>
      </DivPermissoesAcesso>
    </DivPrincipal>
  );
};

export default RelatorioComponente;
