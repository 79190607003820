import React, {useState, useEffect} from 'react';
import Container from '~/componentes/container';
import {getOrcamentos} from '~/servicos/mongo_api';
import Grid from '~/componentes/grid';
import {getNLinhasPadraoGrid} from '~/core';
import TableCell from '@material-ui/core/TableCell';

import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Assessment';
import Tooltip from '@material-ui/core/Tooltip';

import {URLAPI} from '~/servicos/mongo_api';

const Orcamentos = (props) => {
  const [loading, setLoading] = useState(false);
  const [Inicio, setInicio] = useState(true);
  const [Total, setTotal] = useState(0);
  const [Page, setPage] = useState(1);
  const [LinhasPorPagina, setLinhasPorPagina] = useState(
    getNLinhasPadraoGrid(),
  );

  const [Orcamentos, setOrcamentos] = useState([]);

  const CamposHeader = [
    'Cliente',
    'Orçamento',
    'Prazo (Dias)',
    'QTD Horas',
    'Data do Orçamento',
  ];
  const CamposBody = [
    'cliente',
    'nome',
    'prazo',
    'qtd_horas',
    'data_orcamento',
  ];

  useEffect(() => {
    carregarDados();
  }, [Inicio]);

  useEffect(() => {
    //console.log('Page : ' + Page);
    carregarDados();
  }, [Page]);

  const carregarDados = async (PageParm = Page) => {
    setLoading(true);

    let OrcamentosAux = await getOrcamentos();
    setTotal(OrcamentosAux.length);

    setOrcamentos(OrcamentosAux);
    setLoading(false);
  };

  const abrirRelatorioHoras = async (OrcamentoID) => {
    window.open(
      `${URLAPI}/relatorios/orcamento-horas-aux?orcamento_id=${OrcamentoID}`,
      '_blank',
    );
  };

  const funcaoAdicional = (props) => (
    <Tooltip title="Imprimir horas orçamento">
      <IconButton {...props} onClick={() => abrirRelatorioHoras(props.row._id)}>
        <SendIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Container loading={loading} {...props}>
      <Grid
        Rota={'orcamentos'}
        Rows={Orcamentos}
        Total={Total}
        CamposHeader={CamposHeader}
        CamposBody={CamposBody}
        setPage={setPage}
        setLinhasPorPagina={setLinhasPorPagina}
        FuncaoAdicional={funcaoAdicional}
      />
    </Container>
  );
};

export default Orcamentos;
