import React, {useState, useEffect} from 'react';
import Container from '~/componentes/container';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Title from '~/componentes/title';

import ProjetoComponente from '../projeto_componente';

import {
  TextInputAdminOrcamentos,
  SelectInputAdminOrcamentos,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LixeiraOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import {Accordion, AccordionItem} from '~/componentes/accordion';

import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
  getOrcamentos,
  getCombosProjetos,
  cadastrarOrcamento,
  alterarOrcamento,
  deletarOrcamento,
} from '~/servicos/mongo_api';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';
import Button from '@material-ui/core/Button';
import {getMode} from '~/core';
import BlogAutores from '..';
import TitleForm from '~/componentes/titleForm';
import Loading from '~/componentes/loading';

const OrcamentosEditar = (props) => {
  const classes = ClassesBase();
  const [loading, setLoading] = useState(true);
  const [Inicio, setInicio] = useState(true);
  const [Modo, setModo] = useState('Editar');
  const [DisplayMode, setDisplayMode] = useState(false);

  const {id} = useParams();
  const [ID, setID] = useState(id);

  const History = useHistory();
  const Location = useLocation();

  const [Orcamento, setOrcamento] = useState();
  const [OrcamentosLista, setOrcamentosLista] = useState();
  const [OrcamentoSelecionado, setOrcamentoSelecionado] = useState();

  const [Cliente, setCliente] = useState();
  const [Nome, setNome] = useState();
  const [Descricao, setDescricao] = useState();
  const [DataOrcamento, setDataOrcamento] = useState();

  const [DisponibilizaraFonte, setDisponibilizaraFonte] = useState(true);

  const [QTDHoras, setQTDHoras] = useState();
  const [Prazo, setPrazo] = useState();

  const [Projetos, setProjetos] = useState([{}]);

  const [TiposPlataformas, setTiposPlataformas] = useState([]);
  const [TiposPublicacoes, setTiposPublicacoes] = useState([]);
  const [TiposSetups, setTiposSetups] = useState([]);
  const [TiposTarefas, setTiposTarefas] = useState([]);
  const [TiposFuncoes, setTiposFuncoes] = useState([]);

  const [CreatAt, setCreatAt] = useState();

  useEffect(() => {
    let ModoAux = getMode(Location);
    setModo(ModoAux);

    if (ModoAux != 'Editar' && ModoAux != 'Cadastrar') {
      setDisplayMode(true);
    }

    carregarDados(ModoAux);
  }, [Inicio]);

  const carregarDados = async (ModoParm) => {
    setLoading(true);

    //alert(ModoParm);
    if (ModoParm == 'Cadastrar') {
      let OrcamentosListaAux = await getOrcamentos();
      setOrcamentosLista(OrcamentosListaAux);
    } else {
      let OrcamentoAux = await getOrcamentos(id);
      let CombosProjetos = await getCombosProjetos();

      setOrcamento(OrcamentoAux);
      setTiposPlataformas(CombosProjetos.tipos_plataformas);
      setTiposPublicacoes(CombosProjetos.tipos_publicacoes);
      setTiposSetups(CombosProjetos.tipos_setups);
      setTiposTarefas(CombosProjetos.tipos_tarefas);
      setTiposFuncoes(CombosProjetos.tipos_funcoes);

      //console.log('OrcamentoAux : ' + JSON.stringify(OrcamentoAux));
      //console.log('CombosProjetos : ' + JSON.stringify(CombosProjetos));

      formatar(OrcamentoAux);
    }

    setLoading(false);
  };

  const formatar = (OrcamentoParm) => {
    const {
      nome,
      descricao,
      cliente,
      data_orcamento,
      disponibilizara_fonte,
      qtd_horas,
      prazo,
      projetos,
      created_at,
    } = OrcamentoParm;
    setCliente(cliente);
    setNome(nome);
    setDescricao(descricao);
    setDataOrcamento(data_orcamento);
    setDisponibilizaraFonte(disponibilizara_fonte);
    setQTDHoras(qtd_horas);
    setPrazo(prazo);
    setProjetos(projetos ?? [{}]);
    setCreatAt(created_at);
  };

  const validouDados = () => {
    let Validou = true;

    if (!Nome) {
      alert('É necessário inserir um nome');
      Validou = false;
    }

    return Validou;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validouDados()) return;

    switch (Modo) {
      case 'Visualizar':
        History.goBack();
        break;
      case 'Cadastrar':
        cadastrar();
        break;
      case 'Editar':
        editar();
        break;
      case 'Excluir':
        excluir();
        break;

      default:
        console.log('Nenhuma ação escolhida');
        break;
    }
  };

  const salvarEAtualizar = () => {
    editar(null, false);
  };

  const getDados = () => {
    return {
      nome: Nome,
      cliente: Cliente,
      descricao: Descricao,
      data_orcamento: DataOrcamento,
      disponibilizara_fonte: DisponibilizaraFonte,

      // sem projetos "vazios"
      projetos: Projetos.filter((Item) => {
        return !!Item.nome;
      }),
    };
  };

  const cadastrar = async (DadosParm) => {
    setLoading(true);

    let Dados = {
      cliente_nome: Cliente,
      orcamento_nome: Nome,
      orcamento_referencia: OrcamentoSelecionado,
    };

    let Retorno = await cadastrarOrcamento(Dados);
    finalizarAcao(!!(Retorno && Retorno._id), 'CADASTRAR');
  };

  const editar = async (DadosParm, Sair = true) => {
    setLoading(true);

    let Dados = DadosParm ?? getDados();
    let Retorno = await alterarOrcamento(id, Dados);

    if (Sair) {
      finalizarAcao(!!Retorno._id, 'ALTERAR');
    } else {
      //setLoading(false);
      //History.go(0);
      carregarDados('Editar');
    }
  };

  const excluir = async () => {
    setLoading(true);

    if (!window.confirm('Deseja realmente excluir este registro?')) {
      setLoading(false);
      return;
    }

    let Sucesso = await deletarOrcamento(id);
    finalizarAcao(!!Sucesso, 'EXCLUIR');
  };

  const finalizarAcao = (MSG_RESULTADO, MSG_TIPO_ACAO) => {
    localStorage.setItem('@MSG_RESULTADO', MSG_RESULTADO.toString());
    localStorage.setItem('@MSG_TIPO_ACAO', MSG_TIPO_ACAO);

    setLoading(false);
    History.goBack();
  };

  const adicionarProjeto = async () => {
    setProjetos([...Projetos, {}]);
  };

  const salvarProjeto = async (ProjetoItem, index, Recarregar = false) => {
    let Dados = getDados();

    //console.log('index Projeto ' + index);
    //console.log('ProjetoItem ' + JSON.stringify(ProjetoItem));

    //return;

    let TPublicoesAux = ProjetoItem.tipos_publicacoes ?? [];
    ProjetoItem.tipos_publicacoes = TPublicoesAux.filter((Item) => {
      return !!(Item.publicacao_id || Item._id);
    });

    let TarefasAux = ProjetoItem.tarefas ?? [];
    ProjetoItem.tarefas = TarefasAux.filter((Item) => {
      return !!(Item.tipo_tarefa_id || Item._id);
    });

    let FuncionalidadesAux = ProjetoItem.funcionalidades ?? [];
    ProjetoItem.funcionalidades = FuncionalidadesAux.filter((Item) => {
      //console.log('Item : ' + JSON.stringify(Item));
      return !!(Item.nome || Item._id);
    });

    if (Modo == 'Cadastrar') {
      console.log('Modo Cadastrar');

      Dados.projetos = [ProjetoItem];
      cadastrar(Dados);
    } else {
      console.log('Modo Editar');

      let ProjetosAux = Projetos.filter((Item) => {
        //console.log('Item : ' + JSON.stringify(Item));
        return !!Item.nome;
      });

      ProjetosAux[index] = ProjetoItem;

      //console.log('ProjetosAux ' + JSON.stringify(ProjetosAux));

      Dados.projetos = ProjetosAux;

      if (Recarregar || !ProjetoItem._id) {
        //projeto novo
        editar(Dados, false);
        //console.log(`Dados Salvar novo projeto - ${JSON.stringify(Dados)}`);
      } else {
        //console.log('Projetos '+JSON.stringify(Projetos))

        setProjetos(ProjetosAux);
      }
    }
  };

  const excluirProjeto = async (ProjetoItem) => {
    //console.log(ProjetoItem);
    if (!ProjetoItem._id) {
      return;
    }

    let ProjetosAux = Projetos.filter((Item) => {
      return Item._id != ProjetoItem._id;
    });

    //console.log('ProjetosAux ' + JSON.stringify(ProjetosAux));

    setProjetos(ProjetosAux);
  };

  return (
    <Container loading={loading} {...props}>
      <TableContainer component={Paper}>
        <Title>{Modo}</Title>
        <DivConteudoFormulario>
          {Modo == 'Cadastrar' ? (
            <>
              {OrcamentosLista && (
                <Formulario
                  className={classes.Formulario}
                  onSubmit={handleSubmit}>
                  <TextInputAdminOrcamentos
                    required
                    label="Cliente"
                    autoComplete="nome"
                    value={Cliente}
                    onChange={({target}) => setCliente(target.value)}
                    disabled={DisplayMode}
                    autoFocus
                  />

                  <TextInputAdminOrcamentos
                    required
                    label="Nome"
                    autoComplete="nome"
                    value={Nome}
                    onChange={({target}) => setNome(target.value)}
                    disabled={DisplayMode}
                  />

                  <SelectInputAdminOrcamentos
                    required
                    label="Orçamento Referência"
                    value={OrcamentoSelecionado}
                    onChange={({target}) =>
                      setOrcamentoSelecionado(target.value)
                    }
                    displayEmpty={true}
                    renderValue={(Item) => {
                      if (Item) {
                        return OrcamentosLista.find((ItemAux) => {
                          return ItemAux._id == Item;
                        }).nome;
                      } else {
                        return 'Selecione...';
                      }
                    }}>
                    {OrcamentosLista.map((Item) => (
                      <MenuItem value={Item._id}>{Item.nome}</MenuItem>
                    ))}
                  </SelectInputAdminOrcamentos>

                  <br />
                  <DivBotaoVoltar>
                    <Button
                      onClick={() => History.goBack()}
                      fullWidth
                      variant="contained"
                      color="primary">
                      Voltar
                    </Button>
                  </DivBotaoVoltar>
                  {Modo == 'Visualizar' ? (
                    <></>
                  ) : (
                    <DivBotao>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary">
                        Salvar
                      </Button>
                    </DivBotao>
                  )}
                </Formulario>
              )}
            </>
          ) : (
            <>
              {Orcamento && (
                <Formulario
                  className={classes.Formulario}
                  onSubmit={handleSubmit}>
                  <TextInputAdminOrcamentos
                    label="ID"
                    value={id ? id : ''}
                    disabled={true}
                  />

                  <TextInputAdminOrcamentos
                    required
                    label="Cliente"
                    autoComplete="nome"
                    value={Cliente}
                    onChange={({target}) => setCliente(target.value)}
                    disabled={DisplayMode}
                  />

                  <TextInputAdminOrcamentos
                    required
                    label="Nome"
                    autoComplete="nome"
                    autoFocus
                    value={Nome}
                    onChange={({target}) => setNome(target.value)}
                    disabled={DisplayMode}
                  />

                  <TextInputAdminOrcamentos
                    required
                    label="Descricao"
                    autoComplete="nome"
                    value={Descricao}
                    onChange={({target}) => setDescricao(target.value)}
                    disabled={DisplayMode}
                    multiline
                    minRows={5}
                    maxRows={30}
                  />

                  <TextInputAdminOrcamentos
                    label="Data Orçamento"
                    autoComplete="nome"
                    value={DataOrcamento}
                    onChange={({target}) => setDataOrcamento(target.value)}
                    disabled={!!DisplayMode}
                  />

                  <SelectInputAdminOrcamentos
                    required
                    label="Disponibilizará o Fonte"
                    value={DisponibilizaraFonte}
                    onChange={({target}) =>
                      setDisponibilizaraFonte(target.value)
                    }
                    disabled={DisplayMode}>
                    <MenuItem value={true}>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </SelectInputAdminOrcamentos>

                  <TextInputAdminOrcamentos
                    label="QTD de Horas"
                    value={QTDHoras}
                    onChange={({target}) => setQTDHoras(target.value)}
                    disabled={true}
                  />

                  <TextInputAdminOrcamentos
                    label="Prazo (Dias)"
                    value={Prazo}
                    onChange={({target}) => setPrazo(target.value)}
                    disabled={true}
                  />

                  <TitleForm>PROJETOS</TitleForm>

                  <Accordion>
                    <IconButton onClick={adicionarProjeto}>
                      <AddCircleOutlineIcon
                        style={{fontSize: 40, marginTop: -20}}
                      />
                    </IconButton>
                    {!loading &&
                      Projetos &&
                      Projetos.map((ProjetoItem, index) => {
                        return (
                          <AccordionItem
                            header={
                              ProjetoItem.nome
                                ? `${ProjetoItem.nome}${
                                    ProjetoItem.qtd_horas
                                      ? ' - ' + ProjetoItem.qtd_horas + ' horas'
                                      : ''
                                  } `
                                : ``
                            }
                            initialEntered={true}>
                            <IconButton
                              onClick={() => excluirProjeto(ProjetoItem)}>
                              <LixeiraOutlineIcon
                                style={{fontSize: 30, marginTop: 15}}
                              />
                            </IconButton>

                            <ProjetoComponente
                              {...ProjetoItem}
                              salvarProjeto={salvarProjeto}
                              TiposPlataformas={TiposPlataformas}
                              TiposPublicacoes={TiposPublicacoes}
                              TiposSetups={TiposSetups}
                              TiposTarefas={TiposTarefas}
                              TiposFuncoes={TiposFuncoes}
                              index={index}
                            />
                          </AccordionItem>
                        );
                      })}
                  </Accordion>

                  <br />
                  <DivBotaoVoltar>
                    <Button
                      onClick={() => History.goBack()}
                      fullWidth
                      variant="contained"
                      color="primary">
                      Voltar
                    </Button>
                  </DivBotaoVoltar>
                  {Modo == 'Visualizar' ? (
                    <></>
                  ) : (
                    <>
                      {Modo == 'Editar' && (
                        <DivBotao>
                          <Button
                            onClick={salvarEAtualizar}
                            fullWidth
                            variant="contained"
                            color="primary">
                            Salvar
                          </Button>
                        </DivBotao>
                      )}

                      <DivBotao>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary">
                          {Modo == 'Excluir' ? 'Excluir' : 'Salvar e Sair'}
                        </Button>
                      </DivBotao>
                    </>
                  )}
                </Formulario>
              )}
            </>
          )}
        </DivConteudoFormulario>
      </TableContainer>
    </Container>
  );
};

export default OrcamentosEditar;
