import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import 'react-activity/dist/react-activity.css';
import {
  TextInputAdminOrcamentos,
  SelectInputAdminOrcamentos,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';

import {
  DivPermissoesAcesso,
  DivLabelPermissoesAcesso,
  DivRotasPermissoes,
} from './styles';

import DivSublinhada from '~/componentes/div_sublinhada';

import Button from '@material-ui/core/Button';

export const DivPrincipal = styled.div``;

const TipoPublicacaoComponente = (TipoPublicacao = {}) => {
  const {
    salvarTipoPublicacao,
    DisplayMode,
    TiposPublicacoes,
    _id,
    publicacao_id,
    publicacao_nome,
    qtd_horas,
    prazo,
    index,
  } = TipoPublicacao;

  const [Inicio, setInicio] = useState(true);
  const [ID, setID] = useState(_id);

  const [TipoPublicacaoSelecionada, setTipoPublicacaoSelecionada] =
    useState(publicacao_id);

  const [TipoPublicacaoSelecionadaNome, setTipoPublicacaoSelecionadaNome] =
    useState(publicacao_nome);
  const [QTDHoras, setQTDHoras] = useState(qtd_horas ?? 0);

  const [Prazo, setPrazo] = useState(prazo ?? 0);

  useEffect(() => {
    //console.log('TipoPublicacao : ' + JSON.stringify(TipoPublicacao));
  }, [Inicio]);

  const salvarTipoPublicacaoAux = async () => {
    let tipoPublicacaoAux = getDados();
    let Retorno = await salvarTipoPublicacao(tipoPublicacaoAux, index);
  };

  const getDados = () => {
    return {
      _id: ID,
      publicacao_id: TipoPublicacaoSelecionada,
      publicacao_nome: TipoPublicacaoSelecionadaNome,
      qtd_horas: QTDHoras,
      prazo: Prazo,
    };
  };

  const changeTipoPublicacao = (ID) => {
    setTipoPublicacaoSelecionada(ID);

    let TipoPublicacaoAux = TiposPublicacoes.find((ItemAux) => {
      return ItemAux._id == ID;
    });

    setTipoPublicacaoSelecionadaNome(TipoPublicacaoAux.nome);
    setQTDHoras(TipoPublicacaoAux.qtd_horas);
    setPrazo(TipoPublicacaoAux.prazo);
  };

  return (
    <DivPrincipal>
      <DivPermissoesAcesso>
        <SelectInputAdminOrcamentos
          required
          label="
            Tipo de Publicação"
          value={TipoPublicacaoSelecionada}
          onChange={({target}) => changeTipoPublicacao(target.value)}
          disabled={DisplayMode}
          displayEmpty={true}
          renderValue={(Item) => {
            if (Item) {
              return TiposPublicacoes.find((ItemAux) => {
                return ItemAux._id == Item;
              }).nome;
            } else {
              return 'Selecione...';
            }
          }}>
          {TiposPublicacoes.map((Item) => (
            <MenuItem value={Item._id}>{Item.nome}</MenuItem>
          ))}
        </SelectInputAdminOrcamentos>

        <TextInputAdminOrcamentos
          label="QTD Horas"
          autoComplete="nome"
          value={QTDHoras}
          onChange={({target}) => setQTDHoras(target.value)}
          disabled={!!DisplayMode}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Prazo"
          autoComplete="nome"
          value={Prazo}
          onChange={({target}) => setPrazo(target.value)}
          disabled={!!DisplayMode}
          tamanho={'menor'}
        />

        <DivBotao style={{float: 'none'}}>
          <Button
            variant="contained"
            color="secundary"
            onClick={salvarTipoPublicacaoAux}>
            Salvar tipo de Publicação
          </Button>
        </DivBotao>
      </DivPermissoesAcesso>
    </DivPrincipal>
  );
};

export default TipoPublicacaoComponente;
