import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import 'react-activity/dist/react-activity.css';
import {
  TextInputAdminOrcamentos,
  SelectInputAdminOrcamentos,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';

import {
  DivPermissoesAcesso,
  DivLabelPermissoesAcesso,
  DivRotasPermissoes,
} from './styles';

import DivSublinhada from '~/componentes/div_sublinhada';
import Button from '@material-ui/core/Button';
import TitleForm from '~/componentes/titleForm';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {Accordion, AccordionItem} from '~/componentes/accordion';

import TarefaComponente from '../tarefa_componente';

export const DivPrincipal = styled.div``;

const FuncaoComponente = (props = {}) => {
  const {
    DisplayMode,
    salvarFuncao,
    TiposFuncoes,
    TodasFuncoes,

    _id,
    funcao_id,
    funcao_nome,
    descricao,
    qtd_horas,
    prazo,
    ordem,
    index,
  } = props;

  const [Inicio, setInicio] = useState(true);
  const [ID, setID] = useState(_id);

  const [Descricao, setDescricao] = useState(descricao);
  const [QTDHoras, setQTDHoras] = useState(qtd_horas ?? 0);
  const [Prazo, setPrazo] = useState(prazo ?? 0);
  const [Ordem, setOrdem] = useState(ordem ?? 0);

  const [FuncaoSelecionada, setFuncaoSelecionada] = useState(funcao_id);

  const [FuncaoSelecionadaNome, setFuncaoSelecionadaNome] =
    useState(funcao_nome);

  useEffect(() => {
    //console.log('FuncaoAux : ' + JSON.stringify(props));
  }, [Inicio]);

  const salvarFuncaoAux = async () => {
    let FuncaoAux = getDados();
    let Retorno = await salvarFuncao(FuncaoAux, index);
  };

  const getDados = () => {
    return {
      _id: ID,
      descricao: Descricao,
      qtd_horas: QTDHoras,
      prazo: Prazo,
      ordem: Ordem,
      funcao_id: FuncaoSelecionada,
      funcao_nome: FuncaoSelecionadaNome,
    };
  };

  const changeTipoFuncao = (ID) => {
    setFuncaoSelecionada(ID);

    let TipoFuncaoAux = TiposFuncoes.find((ItemAux) => {
      return ItemAux._id == ID;
    });

    //console.log('TipoFuncaoAux : ' + JSON.stringify(TipoFuncaoAux));

    setFuncaoSelecionadaNome(TipoFuncaoAux.nome);
    setQTDHoras(TipoFuncaoAux.qtd_horas);
    setPrazo(TipoFuncaoAux.prazo);
  };

  return (
    <DivPrincipal>
      <DivPermissoesAcesso>
        <SelectInputAdminOrcamentos
          required
          label="Função"
          value={FuncaoSelecionada}
          onChange={({target}) => changeTipoFuncao(target.value)}
          disabled={DisplayMode}
          displayEmpty={true}
          renderValue={(Item) => {
            if (Item) {
              return TiposFuncoes.find((ItemAux) => {
                return ItemAux._id == Item;
              }).nome;
            } else {
              return 'Selecione...';
            }
          }}>
          {TiposFuncoes.map((Item) => {
            if (TodasFuncoes || Item.tipo == 'back') {
              return <MenuItem value={Item._id}>{Item.nome}</MenuItem>;
            }
          })}
        </SelectInputAdminOrcamentos>

        <TextInputAdminOrcamentos
          label="Descrição"
          autoComplete="nome"
          value={Descricao}
          onChange={({target}) => setDescricao(target.value)}
          disabled={!!DisplayMode}
          multiline
          minRows={3}
          maxRows={30}
        />

        <TextInputAdminOrcamentos
          label="QTD Horas"
          autoComplete="nome"
          value={QTDHoras}
          onChange={({target}) => setQTDHoras(target.value)}
          disabled={!!DisplayMode}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Prazo"
          autoComplete="nome"
          value={Prazo}
          onChange={({target}) => setPrazo(target.value)}
          disabled={!!DisplayMode}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Ordem"
          autoComplete="nome"
          value={Ordem}
          onChange={({target}) => setOrdem(target.value)}
          disabled={!!DisplayMode}
        />

        <DivBotao style={{float: 'none'}}>
          <Button
            variant="contained"
            color="secundary"
            onClick={salvarFuncaoAux}>
            Salvar Função
          </Button>
        </DivBotao>
      </DivPermissoesAcesso>
    </DivPrincipal>
  );
};

export default FuncaoComponente;
