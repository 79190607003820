import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import 'react-activity/dist/react-activity.css';
import {
  TextInputAdmin,
  TextInputAdminOrcamentos,
  SelectInputAdminOrcamentos,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';

import {
  DivPermissoesAcesso,
  DivLabelPermissoesAcesso,
  DivRotasPermissoes,
} from './styles';

import DivSublinhada from '~/componentes/div_sublinhada';
import TipoPublicacaoComponente from '../tipo_publicacao_componente';
import TarefaComponente from '../tarefa_componente';
import FuncionalidadeComponente from '../funcionalidade_componente';

import Button from '@material-ui/core/Button';
import TitleForm from '~/componentes/titleForm';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LixeiraOutlineIcon from '@material-ui/icons/DeleteOutline';

import {Accordion, AccordionItem} from '~/componentes/accordion';

export const DivPrincipal = styled.div``;

const ProjetoComponente = (Projeto = {}) => {
  const {
    salvarProjeto,
    DisplayMode,
    TiposPlataformas,
    TiposPublicacoes,
    TiposSetups,
    TiposTarefas,
    TiposFuncoes,
    _id,
    nome,
    descricao,
    tipo_plataforma,
    tipos_publicacoes,
    tipo_setup,
    setup_qtd_horas,
    setup_prazo,
    qtd_horas,
    prazo,
    publicacao_horas,
    disponibilizara_fonte,
    tarefas,
    funcionalidades,
    valor_hora,
    index,
  } = Projeto;

  const [Inicio, setInicio] = useState(true);
  const [ID, setID] = useState(_id);
  const [Nome, setNome] = useState(nome);
  const [Descricao, setDescricao] = useState(descricao);

  const [TipoPlataformaSelecionada, setTipoPlataformaSelecionada] =
    useState(tipo_plataforma);

  const [TipoSetupSelecionado, setTipoSetupSelecionado] = useState(tipo_setup);

  const [TiposPublicacoesSelecionadas, setTiposPublicacoesSelecionadas] =
    useState(tipos_publicacoes);

  const [TarefasSelecionadas, setTarefasSelecionadas] = useState(tarefas);

  const [FuncionalidadesSelecionadas, setFuncionalidadesSelecionadas] =
    useState(funcionalidades);

  const [QTDHoras, setQTDHoras] = useState(qtd_horas);
  const [Prazo, setPrazo] = useState(prazo);

  const [SetupQTDHoras, setSetupQTDHoras] = useState(setup_qtd_horas);
  const [SetupPrazo, setSetupPrazo] = useState(setup_prazo);

  const [ValorHora, setValorHora] = useState(valor_hora ?? 0);

  useEffect(() => {
    //console.log('tipos_publicacoes : ' + tipos_publicacoes);
    //console.log('index projeto : ' + index);

    if (tipos_publicacoes && tipos_publicacoes.length > 0) {
      setTiposPublicacoesSelecionadas(tipos_publicacoes);
    } else {
      setTiposPublicacoesSelecionadas([{}]);
    }

    if (tarefas && tarefas.length > 0) {
      setTarefasSelecionadas(tarefas);
    } else {
      setTarefasSelecionadas([{}]);
    }

    if (funcionalidades && funcionalidades.length > 0) {
      setFuncionalidadesSelecionadas(funcionalidades);
    } else {
      setFuncionalidadesSelecionadas([{}]);
    }

    //console.log('Projeto : ' + JSON.stringify(Projeto));
    //console.log(      'TiposPublicacoesSelecionadas : ' +        JSON.stringify(TiposPublicacoesSelecionadas),    );

    //console.log('TiposPlataformas : ' + JSON.stringify(TiposPlataformas));
    //console.log('TiposPublicacoes : ' + JSON.stringify(TiposPublicacoes));
    //console.log('TiposSetups : ' + JSON.stringify(TiposSetups));
  }, [Inicio]);

  const salvarProjetoAux = async () => {
    let ProjetoAux = getDados();
    let Retorno = await salvarProjeto(ProjetoAux, index, true);

    //console.log("ProjetoAux : "+ProjetoAux);
    //console.log('Retorno salvar projeto: ' + Retorno);
  };

  const getDados = () => {
    return {
      _id: ID,
      nome: Nome,
      descricao: Descricao,
      tipo_plataforma: TipoPlataformaSelecionada,
      tipos_publicacoes: TiposPublicacoesSelecionadas,
      tarefas: TarefasSelecionadas,
      funcionalidades: FuncionalidadesSelecionadas,
      tipo_setup: TipoSetupSelecionado,
      setup_qtd_horas: SetupQTDHoras,
      setup_prazo: SetupPrazo,

      valor_hora: ValorHora,
    };
  };

  const changeTipoPlataforma = (ID) => {
    setTipoPlataformaSelecionada(ID);

    let ValorHoraAux = TiposPlataformas.find((ItemAux) => {
      return ItemAux._id == ID;
    }).valor_hora;

    setValorHora(ValorHoraAux);
  };

  const changeTipoSetup = (ID) => {
    setTipoSetupSelecionado(ID);

    let TipoSetupAux = TiposSetups.find((ItemAux) => {
      return ItemAux._id == ID;
    });

    setSetupQTDHoras(TipoSetupAux.qtd_horas);
    setSetupPrazo(TipoSetupAux.prazo);
  };

  const salvarTipoPublicacao = async (
    TipoPublicacaoItem,
    index_tipo_publicacao,
  ) => {
    //console.log('TipoPublicacaoItem : ' + JSON.stringify(TipoPublicacaoItem));
    let DadosProjeto = getDados();
    let TiposPublicacoesAux = TiposPublicacoesSelecionadas;

    TiposPublicacoesAux[index_tipo_publicacao] = TipoPublicacaoItem;

    setTiposPublicacoesSelecionadas(TiposPublicacoesAux);

    DadosProjeto.tipos_publicacoes = TiposPublicacoesAux;
    salvarProjeto(DadosProjeto, index);
  };

  const excluirTipoPublicacao = async (
    TipoPublicacaoItem,
    index_tipo_publicacao,
  ) => {
    //console.log('TipoPublicacaoItem : ' + JSON.stringify(TipoPublicacaoItem));
    let DadosProjeto = getDados();

    let TiposPublicacoesAux = TiposPublicacoesSelecionadas;
    TiposPublicacoesAux.splice(index_tipo_publicacao, 1);

    setTiposPublicacoesSelecionadas(TiposPublicacoesAux);
    DadosProjeto.tipos_publicacoes = TiposPublicacoesAux;
    salvarProjeto(DadosProjeto, index);
  };

  const salvarTarefa = async (TarefaItem, index_tarefa) => {
    //console.log('TarefaItem : ' + JSON.stringify(TarefaItem));
    //console.log('index Tarefa salvar : ' + index_tarefa);
    let DadosProjeto = getDados();
    let TarefasAux = TarefasSelecionadas;

    TarefasAux[index_tarefa] = TarefaItem;

    //console.log('TarefasAux : ' + JSON.stringify(TarefasAux));

    setTarefasSelecionadas(TarefasAux);

    DadosProjeto.tarefas = TarefasAux;

    //console.log('DadosProjeto : ' + JSON.stringify(DadosProjeto));

    salvarProjeto(DadosProjeto, index);
  };

  const excluirTarefa = async (TarefaItem, index_tarefa) => {
    //console.log('TarefaItem : ' + JSON.stringify(TarefaItem));
    let DadosProjeto = getDados();

    let TarefasAux = TarefasSelecionadas;
    TarefasAux.splice(index_tarefa, 1);

    setTarefasSelecionadas(TarefasAux);
    DadosProjeto.tarefas = TarefasAux;
    salvarProjeto(DadosProjeto, index);
  };

  const salvarFuncionalidade = async (
    FuncionalidadeItem,
    index_funcionalidade,
  ) => {
    //console.log('FuncionalidadeItem : ' + JSON.stringify(FuncionalidadeItem));
    let DadosProjeto = getDados();
    let FuncionalidadesAux = FuncionalidadesSelecionadas;

    let TarefasAux = FuncionalidadeItem.tarefas ?? [];
    FuncionalidadeItem.tarefas = TarefasAux.filter((Item) => {
      //console.log('Item : ' + JSON.stringify(Item));
      return !!(Item.tipo_tarefa_id || Item._id);
    });

    let RelatoriosAux = FuncionalidadeItem.relatorios ?? [];
    FuncionalidadeItem.relatorios = RelatoriosAux.filter((Item) => {
      //console.log('Item : ' + JSON.stringify(Item));
      return !!(Item.nome || Item._id);
    });

    let TelasAux = FuncionalidadeItem.telas ?? [];
    FuncionalidadeItem.telas = TelasAux.filter((Item) => {
      //console.log('Item : ' + JSON.stringify(Item));
      return !!(Item.nome || Item._id);
    });

    FuncionalidadesAux[index_funcionalidade] = FuncionalidadeItem;

    setFuncionalidadesSelecionadas(FuncionalidadesAux);

    DadosProjeto.funcionalidades = FuncionalidadesAux;
    salvarProjeto(DadosProjeto, index);
  };

  const excluirFuncionalidade = async (
    FuncionalidadeItem,
    index_funcionalidade,
  ) => {
    //console.log('FuncionalidadeItem : ' + JSON.stringify(FuncionalidadeItem));
    let DadosProjeto = getDados();

    let FuncionalidadesAux = FuncionalidadesSelecionadas;
    FuncionalidadesAux.splice(index_funcionalidade, 1);

    setFuncionalidadesSelecionadas(FuncionalidadesAux);
    DadosProjeto.funcionalidades = FuncionalidadesAux;
    salvarProjeto(DadosProjeto, index);
  };

  const adicionarTipoPublicacao = async () => {
    setTiposPublicacoesSelecionadas([...TiposPublicacoesSelecionadas, {}]);
  };

  const adicionarTarefa = async () => {
    setTarefasSelecionadas([...TarefasSelecionadas, {}]);
  };

  const adicionarFuncionalidade = async () => {
    setFuncionalidadesSelecionadas([...FuncionalidadesSelecionadas, {}]);
  };

  return (
    <DivPrincipal>
      <DivPermissoesAcesso>
        <TextInputAdminOrcamentos
          required
          label="Nome"
          autoComplete="nome"
          autoFocus
          value={Nome}
          onChange={({target}) => setNome(target.value)}
          disabled={!!DisplayMode}
        />

        <TextInputAdminOrcamentos
          label="Descricao"
          autoComplete="nome"
          value={Descricao}
          onChange={({target}) => setDescricao(target.value)}
          disabled={!!DisplayMode}
          multiline
          minRows={5}
          maxRows={30}
        />

        <TextInputAdminOrcamentos
          label="QTD de Horas"
          value={QTDHoras}
          onChange={({target}) => setQTDHoras(target.value)}
          disabled={true}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Prazo (Dias)"
          value={Prazo}
          onChange={({target}) => setPrazo(target.value)}
          disabled={true}
          tamanho={'menor'}
        />

        <DivSublinhada>
          <Accordion>
            <AccordionItem
              header={
                ValorHora
                  ? `Plataforma - Valor Hora - R$ ${ValorHora},00`
                  : 'Plataforma'
              }>
              <DivPermissoesAcesso>
                <SelectInputAdminOrcamentos
                  required
                  label="Tipo de Plataforma"
                  value={TipoPlataformaSelecionada}
                  onChange={({target}) => changeTipoPlataforma(target.value)}
                  disabled={DisplayMode}
                  displayEmpty={true}
                  renderValue={(Item) => {
                    if (Item) {
                      return TiposPlataformas.find((ItemAux) => {
                        return ItemAux._id == Item;
                      }).nome;
                    } else {
                      return 'Selecione...';
                    }
                  }}>
                  {TiposPlataformas.map((Item) => (
                    <MenuItem value={Item._id}>{Item.nome}</MenuItem>
                  ))}
                </SelectInputAdminOrcamentos>

                <TextInputAdminOrcamentos
                  label="Valor Hora"
                  autoComplete="nome"
                  value={ValorHora}
                  onChange={({target}) => setValorHora(target.value)}
                  disabled={!!DisplayMode}
                  tamanho={'menor'}
                />
              </DivPermissoesAcesso>
            </AccordionItem>
          </Accordion>
        </DivSublinhada>

        <DivSublinhada>
          <Accordion>
            <AccordionItem
              header={
                SetupQTDHoras ? `Setup - ${SetupQTDHoras} horas` : 'Setup'
              }>
              <DivPermissoesAcesso>
                <SelectInputAdminOrcamentos
                  required
                  label="Tipo de Setup"
                  value={TipoSetupSelecionado}
                  onChange={({target}) => changeTipoSetup(target.value)}
                  disabled={DisplayMode}
                  displayEmpty={true}
                  renderValue={(Item) => {
                    if (Item) {
                      return TiposSetups.find((ItemAux) => {
                        return ItemAux._id == Item;
                      }).nome;
                    } else {
                      return 'Selecione...';
                    }
                  }}>
                  {TiposSetups.map((Item) => (
                    <MenuItem value={Item._id}>{Item.nome}</MenuItem>
                  ))}
                </SelectInputAdminOrcamentos>

                <TextInputAdminOrcamentos
                  label="Setup QTD Horas"
                  autoComplete="nome"
                  value={SetupQTDHoras}
                  onChange={({target}) => setSetupQTDHoras(target.value)}
                  disabled={!!DisplayMode}
                  tamanho={'menor'}
                />

                <TextInputAdminOrcamentos
                  label="Setup Prazo"
                  autoComplete="nome"
                  value={SetupPrazo}
                  onChange={({target}) => setSetupPrazo(target.value)}
                  disabled={!!DisplayMode}
                  tamanho={'menor'}
                />
              </DivPermissoesAcesso>
            </AccordionItem>
          </Accordion>
        </DivSublinhada>

        <DivSublinhada>
          <TitleForm>PUBLICAÇÕES</TitleForm>
          <IconButton onClick={adicionarTipoPublicacao}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {TiposPublicacoesSelecionadas &&
              TiposPublicacoesSelecionadas.map((Item, index) => {
                return (
                  <AccordionItem
                    header={
                      Item.publicacao_nome
                        ? `${Item.publicacao_nome} - ${Item.qtd_horas} horas`
                        : ''
                    }>
                    <IconButton
                      onClick={() => excluirTipoPublicacao(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <TipoPublicacaoComponente
                      {...Item}
                      salvarTipoPublicacao={salvarTipoPublicacao}
                      TiposPublicacoes={TiposPublicacoes}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivSublinhada>
          <TitleForm>TAREFAS</TitleForm>
          <IconButton onClick={adicionarTarefa}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {TarefasSelecionadas &&
              TarefasSelecionadas.map((Item, index) => {
                return (
                  <AccordionItem
                    header={
                      Item.tipo_tarefa_nome
                        ? `${Item.tipo_tarefa_nome} - ${Item.qtd_horas} horas`
                        : ''
                    }>
                    <IconButton onClick={() => excluirTarefa(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <TarefaComponente
                      {...Item}
                      salvarTarefa={salvarTarefa}
                      TiposTarefas={TiposTarefas}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivSublinhada>
          <TitleForm>FUNCIONALIDADES</TitleForm>
          <IconButton onClick={adicionarFuncionalidade}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {FuncionalidadesSelecionadas &&
              FuncionalidadesSelecionadas.map((Item, index) => {
                return (
                  <AccordionItem
                    header={
                      Item.nome ? `${Item.nome} - ${Item.qtd_horas} horas` : ''
                    }>
                    <IconButton
                      onClick={() => excluirFuncionalidade(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <FuncionalidadeComponente
                      {...Item}
                      salvarFuncionalidade={salvarFuncionalidade}
                      TiposTarefas={TiposTarefas}
                      TiposFuncoes={TiposFuncoes}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivBotao style={{float: 'none'}}>
          <Button
            variant="contained"
            color="secundary"
            onClick={salvarProjetoAux}>
            Salvar Projeto
          </Button>
        </DivBotao>
      </DivPermissoesAcesso>
    </DivPrincipal>
  );
};

export default ProjetoComponente;
