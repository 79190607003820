export const URLAPI = 'https://fwc-api-sqog2tqmna-rj.a.run.app';
//export const URLAPI = 'http://localhost:3000';

export const getOrcamentos = async (OrcamentoID) => {
  try {
    let Resp = await fetch(
      `${URLAPI}/orcamentos/consultar?orcamento_id=${OrcamentoID ?? ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/html, */*',
          'Content-Type': 'application/json',
        },

        //body: JSON.stringify({orcamento_id: OrcamentoID, }),
      },
    );

    let RespJson = Resp ? await Resp.json() : false;

    //console.log('Resp : ' + Resp);
    //console.log('Resp : ' + JSON.stringify(RespJson));

    return RespJson ?? [];
  } catch (error) {
    console.log('Erro : ' + error);
    return '';
  }
};

export const getRelHorasOrcamento = async (OrcamentoID) => {
  try {
    let Resp = await fetch(
      `${URLAPI}/orcamentos/relatorio-horas?orcamento_id=${OrcamentoID}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/html, */*',
          'Content-Type': 'application/json',
        },
      },
    );

    let RespJson = Resp ? await Resp.json() : false;

    //console.log('Resp : ' + Resp);
    //console.log('Resp : ' + JSON.stringify(RespJson));

    return RespJson ?? [];
  } catch (error) {
    console.log('Erro : ' + error);
    return '';
  }
};

export const getCombosProjetos = async (OrcamentoID) => {
  try {
    let Resp = await fetch(`${URLAPI}/geral/consultar/combos-projetos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/html, */*',
        'Content-Type': 'application/json',
      },

      //body: JSON.stringify({orcamento_id: OrcamentoID, }),
    });

    let RespJson = Resp ? await Resp.json() : false;

    //console.log('Resp : ' + Resp);
    //console.log('Resp : ' + JSON.stringify(RespJson));

    return RespJson ?? [];
  } catch (error) {
    console.log('Erro : ' + error);
    return '';
  }
};

export const cadastrarOrcamento = async (Orcamento) => {
  try {
    let Resp = await fetch(`${URLAPI}/orcamentos/cadastrar`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/html, */*',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(Orcamento),
    });

    let RespJson = Resp ? await Resp.json() : false;

    //console.log('Resp : ' + Resp);
    console.log('Resp : ' + JSON.stringify(RespJson));

    if (RespJson) {
      return RespJson;
    } else {
      return [];
    }
  } catch (error) {
    console.log('Erro : ' + error);
    return '';
  }
};

export const alterarOrcamento = async (ID, Orcamento) => {
  try {
    console.log('Orcamento : ' + JSON.stringify(Orcamento));

    let Resp = await fetch(`${URLAPI}/orcamentos/alterar`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/html, */*',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({...Orcamento, orcamento_id: ID}),
    });

    let RespJson = Resp ? await Resp.json() : false;

    //console.log('Resp : ' + Resp);
    console.log('Resp : ' + JSON.stringify(RespJson));

    if (RespJson) {
      return RespJson;
    } else {
      return [];
    }
  } catch (error) {
    console.log('Erro : ' + error);
    return '';
  }
};

export const deletarOrcamento = async (OrcamentoID) => {
  try {
    let Resp = await fetch(`${URLAPI}/orcamentos/excluir`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json, text/html, */*',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({orcamento_id: OrcamentoID}),
    });

    let RespJson = Resp ? await Resp.json() : false;

    //console.log('Resp : ' + Resp);
    console.log('Resp : ' + JSON.stringify(RespJson));

    if (RespJson) {
      return RespJson;
    } else {
      return [];
    }
  } catch (error) {
    console.log('Erro : ' + error);
    return '';
  }
};
