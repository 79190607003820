import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import 'react-activity/dist/react-activity.css';
import {
  TextInputAdminOrcamentos,
  SelectInputAdminOrcamentos,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';

import {
  DivPermissoesAcesso,
  DivLabelPermissoesAcesso,
  DivRotasPermissoes,
} from './styles';

import DivSublinhada from '~/componentes/div_sublinhada';

import Button from '@material-ui/core/Button';

export const DivPrincipal = styled.div``;

const TarefaComponente = (props = {}) => {
  const {
    DisplayMode,
    salvarTarefa,
    TiposTarefas,
    _id,
    tipo_tarefa_id,
    tipo_tarefa_nome,
    nome,
    descricao,
    qtd_horas,
    prazo,
    index,
  } = props;

  const [Inicio, setInicio] = useState(true);
  const [ID, setID] = useState(_id);

  const [TipoTarefaSelecionada, setTipoTarefaSelecionada] =
    useState(tipo_tarefa_id);
  const [TipoTarefaSelecionadaNome, setTipoTarefaSelecionadaNome] =
    useState(tipo_tarefa_nome);

  const [Nome, setNome] = useState(nome);

  const [Descricao, setDescricao] = useState(descricao);
  const [QTDHoras, setQTDHoras] = useState(qtd_horas ?? 0);
  const [Prazo, setPrazo] = useState(prazo ?? 0);

  useEffect(() => {
    //console.log('Tarefa : ' + JSON.stringify(props));
    //console.log('index Tarefa : ' + index);
  }, [Inicio]);

  const salvarTarefaAux = async () => {
    let TarefaAux = getDados();
    let Retorno = await salvarTarefa(TarefaAux, index);
  };

  const getDados = () => {
    return {
      _id: ID,
      tipo_tarefa_id: TipoTarefaSelecionada,
      tipo_tarefa_nome: TipoTarefaSelecionadaNome,
      nome: Nome,
      descricao: Descricao,
      qtd_horas: QTDHoras,
      prazo: Prazo,
    };
  };

  const changeTipoTarefa = (ID) => {
    setTipoTarefaSelecionada(ID);

    let TipoTarefaAux = TiposTarefas.find((ItemAux) => {
      return ItemAux._id == ID;
    });

    //console.log('TipoTarefaAux : ' + JSON.stringify(TipoTarefaAux));

    if (TipoTarefaAux.nome != 'Outras') {
      setNome(null);
    }

    setTipoTarefaSelecionadaNome(TipoTarefaAux.nome);
    setQTDHoras(TipoTarefaAux.qtd_horas);
    setPrazo(TipoTarefaAux.prazo);
  };

  return (
    <DivPermissoesAcesso>
      <SelectInputAdminOrcamentos
        required
        label="
            Tarefa"
        value={TipoTarefaSelecionada}
        onChange={({target}) => changeTipoTarefa(target.value)}
        disabled={DisplayMode}
        displayEmpty={true}
        renderValue={(Item) => {
          if (Item) {
            return TiposTarefas.find((ItemAux) => {
              return ItemAux._id == Item;
            }).nome;
          } else {
            return 'Selecione...';
          }
        }}>
        {TiposTarefas.map((Item) => (
          <MenuItem value={Item._id}>{Item.nome}</MenuItem>
        ))}
      </SelectInputAdminOrcamentos>

      {TipoTarefaSelecionadaNome == 'Outras' && (
        <TextInputAdminOrcamentos
          label="Nome"
          autoComplete="nome"
          value={Nome}
          onChange={({target}) => setNome(target.value)}
          disabled={!!DisplayMode}
        />
      )}

      <TextInputAdminOrcamentos
        label="Descrição"
        autoComplete="nome"
        value={Descricao}
        onChange={({target}) => setDescricao(target.value)}
        disabled={!!DisplayMode}
        multiline
        minRows={3}
        maxRows={30}
      />

      <TextInputAdminOrcamentos
        label="QTD Horas"
        autoComplete="nome"
        value={QTDHoras}
        onChange={({target}) => setQTDHoras(target.value)}
        disabled={!!DisplayMode}
        tamanho={'menor'}
      />

      <TextInputAdminOrcamentos
        label="Prazo"
        autoComplete="nome"
        value={Prazo}
        onChange={({target}) => setPrazo(target.value)}
        disabled={!!DisplayMode}
        tamanho={'menor'}
      />

      <DivBotao style={{float: 'none'}}>
        <Button variant="contained" color="secundary" onClick={salvarTarefaAux}>
          Salvar Tarefa
        </Button>
      </DivBotao>
    </DivPermissoesAcesso>
  );
};

export default TarefaComponente;
