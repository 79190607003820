import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import 'react-activity/dist/react-activity.css';
import {
  TextInputAdminOrcamentos,
  SelectInputAdminOrcamentos,
} from '~/componentes/TextInputAdmin';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DivConteudoFormulario,
  Formulario,
  DivBotao,
  DivBotaoVoltar,
  ClassesBase,
} from '~/styles_base';

import {
  DivPermissoesAcesso,
  DivLabelPermissoesAcesso,
  DivRotasPermissoes,
} from './styles';

import DivSublinhada from '~/componentes/div_sublinhada';
import Button from '@material-ui/core/Button';
import TitleForm from '~/componentes/titleForm';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LixeiraOutlineIcon from '@material-ui/icons/DeleteOutline';
import {Accordion, AccordionItem} from '~/componentes/accordion';

import TelaComponente from '../tela_componente';
import TarefaComponente from '../tarefa_componente';
import RelatorioComponente from '../relatorio_componente';

export const DivPrincipal = styled.div``;

const FuncionalidadeComponente = (props = {}) => {
  const {
    DisplayMode,
    salvarFuncionalidade,

    nome,
    _id,
    descricao,
    qtd_horas,
    prazo,
    ordem,
    TiposTarefas,
    TiposFuncoes,
    tarefas,
    relatorios,
    telas,
    index,
  } = props;

  const [Inicio, setInicio] = useState(true);
  const [ID, setID] = useState(_id);

  const [Nome, setNome] = useState(nome);

  const [Descricao, setDescricao] = useState(descricao);
  const [QTDHoras, setQTDHoras] = useState(qtd_horas ?? 0);
  const [Prazo, setPrazo] = useState(prazo ?? 0);
  const [Ordem, setOrdem] = useState(ordem ?? 0);

  const [TarefasSelecionadas, setTarefasSelecionadas] = useState(tarefas);
  const [RelatoriosSelecionados, setRelatoriosSelecionados] =
    useState(relatorios);

  const [TelasSelecionadas, setTelasSelecionadas] = useState(telas);

  useEffect(() => {
    //console.log('FuncionalidadeAux : ' + JSON.stringify(props));

    if (tarefas && tarefas.length > 0) {
      setTarefasSelecionadas(tarefas);
    } else {
      setTarefasSelecionadas([{}]);
    }

    if (relatorios && relatorios.length > 0) {
      setRelatoriosSelecionados(relatorios);
    } else {
      setRelatoriosSelecionados([{}]);
    }

    if (telas && telas.length > 0) {
      setTelasSelecionadas(telas);
    } else {
      setTelasSelecionadas([{}]);
    }
  }, [Inicio]);

  const salvarFuncionalidadeAux = async () => {
    let FuncionalidadeAux = getDados();
    let Retorno = await salvarFuncionalidade(FuncionalidadeAux, index);
  };

  const getDados = () => {
    return {
      _id: ID,
      nome: Nome,
      descricao: Descricao,
      qtd_horas: QTDHoras,
      prazo: Prazo,
      ordem: Ordem,
      tarefas: TarefasSelecionadas,
      relatorios: RelatoriosSelecionados,
      telas: TelasSelecionadas,
    };
  };

  const salvarTarefa = async (TarefaItem, index_tarefa) => {
    //console.log('TarefaItem : ' + JSON.stringify(TarefaItem));
    let DadosFuncionalidade = getDados();
    let TarefasAux = TarefasSelecionadas;

    TarefasAux[index_tarefa] = TarefaItem;

    setTarefasSelecionadas(TarefasAux);
    DadosFuncionalidade.tarefas = TarefasAux;

    //console.log('DadosProjeto : ' + JSON.stringify(DadosProjeto));

    salvarFuncionalidade(DadosFuncionalidade, index);
  };

  const excluirTarefa = async (TarefaItem, index_tarefa) => {
    //console.log('TarefaItem : ' + JSON.stringify(TarefaItem));

    let DadosFuncionalidade = getDados();

    let TarefasAux = TarefasSelecionadas;
    TarefasAux.splice(index_tarefa, 1);

    setTarefasSelecionadas(TarefasAux);
    DadosFuncionalidade.tarefas = TarefasAux;
    salvarFuncionalidade(DadosFuncionalidade, index);
  };

  const salvarRelatorio = async (RelatorioItem, index_relatorio) => {
    //console.log('RelatorioItem : ' + JSON.stringify(RelatorioItem));
    let DadosFuncionalidade = getDados();
    let RelatoriosAux = RelatoriosSelecionados;

    let FuncoesAux = RelatorioItem.funcoes ?? [];
    RelatorioItem.funcoes = FuncoesAux.filter((Item) => {
      //console.log('Item : ' + JSON.stringify(Item));
      return !!(Item.funcao_id || Item._id);
    });

    RelatoriosAux[index_relatorio] = RelatorioItem;

    setRelatoriosSelecionados(RelatoriosAux);

    DadosFuncionalidade.relatorios = RelatoriosAux;
    salvarFuncionalidade(DadosFuncionalidade, index);
  };

  const excluirRelatorio = async (RelatorioItem, index_relatorio) => {
    //console.log('RelatorioItem : ' + JSON.stringify(RelatorioItem));

    let DadosFuncionalidade = getDados();

    let RelatoriosAux = RelatoriosSelecionados;
    RelatoriosAux.splice(index_relatorio, 1);

    setRelatoriosSelecionados(RelatoriosAux);
    DadosFuncionalidade.relatorios = RelatoriosAux;
    salvarFuncionalidade(DadosFuncionalidade, index);
  };

  const salvarTela = async (TelaItem, index_tela) => {
    //console.log('TelaItem : ' + JSON.stringify(TelaItem));
    let DadosFuncionalidade = getDados();
    let TelasAux = TelasSelecionadas;

    let FuncoesAux = TelaItem.funcoes ?? [];
    TelaItem.funcoes = FuncoesAux.filter((Item) => {
      //console.log('Item : ' + JSON.stringify(Item));
      return !!(Item.funcao_id || Item._id);
    });

    TelasAux[index_tela] = TelaItem;

    setTelasSelecionadas(TelasAux);

    DadosFuncionalidade.telas = TelasAux;
    salvarFuncionalidade(DadosFuncionalidade, index);
  };

  const excluirTela = async (TelaItem, index_tela) => {
    //console.log('TelaItem : ' + JSON.stringify(TelaItem));

    let DadosFuncionalidade = getDados();

    let TelasAux = TelasSelecionadas;
    TelasAux.splice(index_tela, 1);

    setTelasSelecionadas(TelasAux);
    DadosFuncionalidade.telas = TelasAux;
    salvarFuncionalidade(DadosFuncionalidade, index);
  };

  const adicionarTarefa = async () => {
    setTarefasSelecionadas([...TarefasSelecionadas, {}]);
  };

  const adicionarRelatorio = async () => {
    setRelatoriosSelecionados([...RelatoriosSelecionados, {}]);
  };

  const adicionarTela = async () => {
    setTelasSelecionadas([...TelasSelecionadas, {}]);
  };

  return (
    <DivPrincipal>
      <DivPermissoesAcesso>
        <TextInputAdminOrcamentos
          label="Nome"
          autoComplete="nome"
          value={Nome}
          onChange={({target}) => setNome(target.value)}
          disabled={!!DisplayMode}
        />

        <TextInputAdminOrcamentos
          label="Descrição"
          autoComplete="nome"
          value={Descricao}
          onChange={({target}) => setDescricao(target.value)}
          disabled={!!DisplayMode}
          multiline
          minRows={3}
          maxRows={30}
        />

        <TextInputAdminOrcamentos
          label="Ordem"
          autoComplete="nome"
          value={Ordem}
          onChange={({target}) => setOrdem(target.value)}
          disabled={!!DisplayMode}
        />

        <TextInputAdminOrcamentos
          label="QTD Horas"
          autoComplete="nome"
          value={QTDHoras}
          onChange={({target}) => setQTDHoras(target.value)}
          disabled={true}
          tamanho={'menor'}
        />

        <TextInputAdminOrcamentos
          label="Prazo"
          autoComplete="nome"
          value={Prazo}
          onChange={({target}) => setPrazo(target.value)}
          disabled={true}
          tamanho={'menor'}
        />

        <DivSublinhada>
          <TitleForm>TELAS</TitleForm>
          <IconButton onClick={adicionarTela}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {TelasSelecionadas &&
              TelasSelecionadas.map((Item, index) => {
                return (
                  <AccordionItem
                    header={
                      Item.nome ? `${Item.nome} - ${Item.qtd_horas} horas` : ''
                    }>
                    <IconButton onClick={() => excluirTela(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <TelaComponente
                      {...Item}
                      salvarTela={salvarTela}
                      TiposFuncoes={TiposFuncoes}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivSublinhada>
          <TitleForm>TAREFAS</TitleForm>
          <IconButton onClick={adicionarTarefa}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {TarefasSelecionadas &&
              TarefasSelecionadas.map((Item, index) => {
                return (
                  <AccordionItem
                    header={
                      Item.tipo_tarefa_nome
                        ? `${Item.tipo_tarefa_nome} - ${Item.qtd_horas} horas`
                        : ''
                    }>
                    <IconButton onClick={() => excluirTarefa(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <TarefaComponente
                      {...Item}
                      salvarTarefa={salvarTarefa}
                      TiposTarefas={TiposTarefas}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivSublinhada>
          <TitleForm>RELATÓRIOS</TitleForm>
          <IconButton onClick={adicionarRelatorio}>
            <AddCircleOutlineIcon style={{fontSize: 40, marginTop: -20}} />
          </IconButton>

          <Accordion>
            {RelatoriosSelecionados &&
              RelatoriosSelecionados.map((Item, index) => {
                return (
                  <AccordionItem
                    header={
                      Item.nome ? `${Item.nome} - ${Item.qtd_horas} horas` : ''
                    }>
                    <IconButton onClick={() => excluirRelatorio(Item, index)}>
                      <LixeiraOutlineIcon
                        style={{fontSize: 30, marginTop: -5, marginBottom: -5}}
                      />
                    </IconButton>
                    <RelatorioComponente
                      {...Item}
                      salvarRelatorio={salvarRelatorio}
                      TiposFuncoes={TiposFuncoes}
                      index={index}
                    />
                  </AccordionItem>
                );
              })}
          </Accordion>
        </DivSublinhada>

        <DivBotao style={{float: 'none'}}>
          <Button
            variant="contained"
            color="secundary"
            onClick={salvarFuncionalidadeAux}>
            Salvar Funcionalidade
          </Button>
        </DivBotao>
      </DivPermissoesAcesso>
    </DivPrincipal>
  );
};

export default FuncionalidadeComponente;
