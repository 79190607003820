import firebase from 'firebase/app';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAxkwHXn-cE3Si38ik-vW5xlWmUy7wDs5I',
  authDomain: 'site-fwc.firebaseapp.com',
  databaseURL: 'https://site-fwc.firebaseio.com',
  projectId: 'site-fwc',
  storageBucket: 'site-fwc.appspot.com',
  messagingSenderId: '774062459265',
  appId: '1:774062459265:web:9e302c768e9473ae54c080',
  measurementId: 'G-P04BR3Z780',
};

export const init = () => {
  firebase.initializeApp(firebaseConfig);
};
